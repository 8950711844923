<template>
  <div class="charts">
    <header class="mb30">
      <section>
        <div class="page-title">运营大屏</div>
        <div class="show-area">
          <div class="show-item">
            <div class="title">
              <span class="color-flag" style="background: #6092F9"></span>
              平台租电总收入（元）
            </div>
            <div class="content">{{ list.totalLeaseIncomeMoney || 0 }}</div>
          </div>
          <div class="show-item">
            <div class="title">
              <span class="color-flag" style="background: #F4826B"></span>
              租赁电池数量（台）
            </div>
            <div class="content">{{ list.leaseDevice || 0 }}</div>
          </div>
          <div class="show-item">
            <div class="title">
              <span class="color-flag" style="background: #FABC41"></span>
              租电设备总数量（台）
            </div>
            <div class="content">{{ list.leaseTotalDevice || 0 }}</div>
          </div>
          <div class="show-item">
            <div class="title">
              <span class="color-flag" style="background: #65CE9A"></span>
              当前租电总押金（元）
            </div>
            <div class="content">{{ list.leaseCashPledge || 0 }}</div>
          </div>
        </div>
      </section>
    </header>


    <footer class="gridBox">
      <!-- 租电每日营收 -->
      <div class="chartsH bor-rad headLeft section-wrapper">
        <!-- <span class="leftTitle">坐标轴根据金额</span> -->
        <lttEcharts :option="dayIncomeOption" refLabel="dayIncomeOption"></lttEcharts>
      </div>
      <!-- 每日租电付费订单数量 -->
      <div class="chartsH bor-rad headRight section-wrapper">
        <lttEcharts :option="dayOrderOption" refLabel="dayOrderOption"></lttEcharts>
      </div>
      <!-- 租电每日押金新增 -->
      <div class="chartsH bor-rad seconLeft section-wrapper">
        <lttEcharts :option="dayCashAdd" refLabel="dayCashAdd"></lttEcharts>
      </div>
      <!-- 租电用户变动数量 -->
      <div class="chartsH bor-rad seconRight section-wrapper">
        <lttEcharts :option="rentChangeCount" refLabel="rentChangeCount"></lttEcharts>
      </div>
      <!-- 用户总数和逾期用户数占比 -->
      <div class="chartsH bor-rad thridLeft section-wrapper">
        <lttEcharts :option="lateTime" refLabel="lateTime"></lttEcharts>
      </div>
      <!-- 设备总数和租赁设备数占比 -->
      <div class="chartsH bor-rad thridRight section-wrapper">
        <lttEcharts :option="rentRatio" refLabel="rentRatio"></lttEcharts>
      </div>
    </footer>
  </div>
</template>

<script>
import lttEcharts from "@/components/lttEcharts";
import {getStatistics, getStatisticsChart} from "@/api/dataStatement"

export default {
  data() {
    return {
      list: [],
      // 租电每日营收
      dayIncomeOption: {
         tooltip: {
          confine: true
        },
        color: ['#6395FA'],
        title: {
          text: '租电每日营收(元)'
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        yAxis: {
          name: '营收金额（元）',
          nameLocation: 'center',
          nameGap: '50',
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: '40%',
          }
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: '{b0}<br />营收金额&nbsp &nbsp<span style="color: #222">{c0}</span>元'
        },
      },
      // 每日租电付费订单
      dayOrderOption: {
         tooltip: {
          confine: true
        },
        color: ['#6395FA'],
        title: {
          text: '每日付费订单数量',
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
          }
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: '{b0}<br />订单数量&nbsp &nbsp<span style="color: #222">{c0}</span>'
        },
      },
      // 每日押金新增
      dayCashAdd: {
         tooltip: {
          confine: true
        },
        color: ['#73DEB3'],
        title: {
          text: '租电每日押金新增(元)'
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: '押金新增（元）',
          nameLocation: 'center',
          nameGap: '50',
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: '40%',
          }
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: '{b0}<br />押金新增&nbsp &nbsp<span style="color: #222">{c0}</span>元'
        },
      },
      // 租电用户变动数量
      rentChangeCount: {
         tooltip: {
          confine: true
        },
        color: ['#73DEB3', '#EC876F', '#6395FA'],
        title: {
          text: "租电用户变动数量"
        },
        legend: {
          data: ["新增", "退租", "净增"],
          icon: 'line'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: "新增",
            type: "line",
            stack: "总量1",
            data: [],
          },
          {
            name: "退租",
            type: "line",
            stack: "总量2",
            data: [],
          },
          {
            name: "净增",
            type: "line",
            stack: "总量3",
            data: [],
          }
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: (params) => {
            let result = params[0].axisValue +'<br />'
            result = result + params.reduce((lastResult, item, index) => {
              return lastResult + item.marker + item.seriesName + '&nbsp &nbsp<span style="color: #222">' + item.value + '</span><br />'
            }, '')
            return result
          }
        },
      },
      // 设备总数和租赁设备占比
      rentRatio: {
         tooltip: {
          confine: true
        },
        color: ['#73DEB3', '#dddddd'],
        title: {
          text: '设备总数和租赁设备数占比'
        },
        tooltip: {
          trigger: "item",
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: '{b0}<br />设备数量&nbsp &nbsp<span style="color: #222">{c0}</span>台'
        },
        legend: {
          data: ["租赁设备", "未租赁"]
        },
        series: [
          {
            name: "设备总数和租赁设备数占比",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'inside',
              formatter: '{d}%',
              color: '#222222'
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 0, name: "租赁设备"},
              {value: 0, name: "未租赁"}
            ]
          }
        ],
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: "设备总数（台）",
              textAlign: 'center',
              fill: '#000',
              width: 30,
              height: 30,
              fontSize: 14,
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '50%',
            style: {
              text: '',
              textAlign: 'center',
              fill: '#000',
              width: 30,
              height: 30,
              fontSize: 30,
              fontWeight: 'bold'
            }
          }
        ],
      },
      // 用户总数和逾期占比
      lateTime: {
        tooltip: {
          confine: true
        },
        color: ['#6395FA', '#dddddd'],
        title: {
          text: '用户总数和逾期用户数占比'
        },
        tooltip: {
          trigger: "item",
          backgroundColor: '#fff',
          textStyle: {
            color: '#999'
          },
          extraCssText: 'box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1)',
          formatter: '{b0}<br />用户数量&nbsp &nbsp<span style="color: #222">{c0}</span>人'
        },
        legend: {
          data: ["未逾期用户", "逾期用户"]
        },
        series: [
          {
            name: "用户总数和逾期用户数占比",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'inside',
              formatter: '{d}%',
              color: '#222222'
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 0, name: "未逾期用户"},
              {value: 0, name: "逾期用户"}
            ]
          }
        ],
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: "用户总数（人）",
              textAlign: 'center',
              fill: '#000',
              width: 30,
              height: 30,
              fontSize: 14,
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '50%',
            style: {
              text: '',
              textAlign: 'center',
              fill: '#000',
              width: 30,
              height: 30,
              fontSize: 30,
              fontWeight: 'bold'
            }
          }
        ],
      },
    };
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    async getStatistics() {
      try {
        const res = await getStatistics();
        this.list = res.mdata.leaseOperationTimeDto;
        const chart = await getStatisticsChart();
        // 新增用户
        let addUserNumXArr = [];//x轴数据
        let addUserNumData = [];//新增y轴数据
        let addUserNum = chart.mdata.leaseOperationTimeChartDto.addUserNum;
        addUserNum.forEach(el => {
          addUserNumXArr.push(el.day);
          addUserNumData.push(el.leaseIncome);
        });
        this.rentChangeCount.xAxis.data = addUserNumXArr;
        this.rentChangeCount.series[0].data = addUserNumData;
        // 净增用户数量
        let netUserNumData = [];
        let netUserNum = chart.mdata.leaseOperationTimeChartDto.netUserNum;
        netUserNum.forEach(el => {
          netUserNumData.push(el.leaseIncome);
        });
        this.rentChangeCount.series[2].data = netUserNumData;
        // 退还用户数
        let returnUserNumData = [];
        let returnUserNum = chart.mdata.leaseOperationTimeChartDto.returnUserNum;
        returnUserNum.forEach(el => {
          returnUserNumData.push(el.leaseIncome);
        });
        this.rentChangeCount.series[1].data = returnUserNumData;

        // 租电每日押金新增
        let cashPledgeData = [];
        let cashPledgeXArr = [];
        let cashPledge = chart.mdata.leaseOperationTimeChartDto.cashPledge;
        cashPledge.forEach(el => {
          cashPledgeData.push(el.leaseIncome);
          cashPledgeXArr.push(el.day);
        });
        this.dayCashAdd.xAxis.data = cashPledgeXArr;
        this.dayCashAdd.series[0].data = cashPledgeData;
        // 租电每日营收
        let leaseIncomeXArr = [];
        let leaseIncomeData = [];
        let leaseIncome = chart.mdata.leaseOperationTimeChartDto.leaseIncome;
        leaseIncome.forEach(el => {
          leaseIncomeXArr.push(el.day);
          leaseIncomeData.push(el.leaseIncome);
        });
        this.dayIncomeOption.xAxis.data = leaseIncomeXArr;
        this.dayIncomeOption.series[0].data = leaseIncomeData;

        // 每日租电付费订单数
        let orderNumXArr = [];
        let orderNumData = [];
        let orderNum = chart.mdata.leaseOperationTimeChartDto.orderNum;
        orderNum.forEach(el => {
          orderNumXArr.push(el.day);
          orderNumData.push(el.leaseIncome);
        });
        this.dayOrderOption.xAxis.data = orderNumXArr;
        this.dayOrderOption.series[0].data = orderNumData;
        // 用户总数和逾期用户占比
        this.lateTime.graphic[1].style.text = +this.list.leaseUserAmount//注册总用户数
        this.lateTime.series[0].data[0].value = +this.list.leaseUserAmount - +this.list.leaseExpireUserAmount;//注册总用户数
        this.lateTime.series[0].data[1].value = this.list.leaseExpireUserAmount;//租赁过期用户数
        // 设备总数和租赁设备占比
        this.rentRatio.graphic[1].style.text = +this.list.leaseTotalDevice//设备总数
        this.rentRatio.series[0].data[1].value = +this.list.leaseTotalDevice - +this.list.leaseDevice;//设备总数
        this.rentRatio.series[0].data[0].value = this.list.leaseDevice;//租赁电池数


      } catch (e) {
        console.log(e);
      }
    }
  },

  components: {
    lttEcharts
  }
};
</script>

<style scoped lang="less">
.el-row.header {
  .el-col {
    width: 20%;
  }
}

.page-title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 20px;
}

.show-area {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    height: 100px;
    box-shadow: 1px 1px 6px 0 rgba(45, 49, 53, 0.1);
    background: #ffffff;
    &:nth-child(n) {
      margin-right: 15px;
    }
    &:last-child {
      margin-right: 0;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: small;
      font-weight: bold;
      .color-flag {
        display: inline-block;
        height: 20px;
        width: 10px;
        margin-right: 10px;
      }
    }
    .content {
      font-size: x-large;
    }
  }
}


.gridBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "a a b b"
    "c c d d"
    "e e f f";

  gap: 20px;

  .chartsH {
    // border: 1px solid black;
    .leftTitle {
      // margin-left:"20px";
      // width:"16px";
    }
  }

  .headLeft {
    grid-area: a;

  }

  .headRight {
    grid-area: b;
  }

  .seconLeft {
    grid-area: c;
  }

  .seconRight {
    grid-area: d;
  }

  .thridLeft {
    grid-area: e;
  }

  .thridRight {
    grid-area: f;
  }

}
</style>
